<template>
  <div class="DetailsCard">
    <div v-if="hasHeaderSlot" class="DetailsCard__header">
      <slot name="header" />
    </div>
    <div class="DetailsCard__body">
      <Details :data="details" :columnSize="Sizes.SMALL" />
      <slot name="default" />
    </div>
    <div v-if="hasControlsSlot" class="DetailsCard__footer">
      <slot name="controls" />
    </div>
  </div>
</template>

<script>
import Details, { Sizes, Formats } from '@scenes/SelfserviceScene/components/Details';

export { Formats };

export default {
  name: 'DetailsCard',

  components: {
    Details,
  },

  enums: {
    Sizes,
  },

  props: {
    details: {
      type: Array,
      required: true,
    },
  },

  computed: {
    hasHeaderSlot() {
      return !!this.$scopedSlots.header;
    },
    hasControlsSlot() {
      return !!this.$scopedSlots.controls;
    },
  },
};
</script>

<style lang="scss" scoped>
.DetailsCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $color-prim-grey;
  border-radius: $border-radius;
  padding: 20px;
  background: #ffffff;
  gap: 20px;

  .DetailsCard__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .DetailsCard__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .DetailsCard__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid $color-prim-grey;
    gap: 10px;
    padding-top: 10px;
  }
}
</style>
