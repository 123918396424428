<template>
  <div class="PhoneInput">
    <CountrySelect
      showCodes
      class="PhoneInput__select"
      v-model="code"
      :disabled="!hasInitializedPhoneInfo"
    />
    <div class="PhoneInput__inputContainer">
      <span v-if="!hideCountryCode" class="PhoneInput__code">{{ codeString }}</span>
      <input
        :style="!hideCountryCode && `padding-left: ${1 + 0.6 * codeString.length}em;`"
        type="tel"
        :maxlength="10"
        :disabled="!hasInitializedPhoneInfo || numberDisabled"
        class="PhoneInput__input"
        v-model="input"
        @beforeinput="beforeInput"
        @focusin="focus = true"
        @focusout="focus = false"
      />
    </div>
  </div>
</template>

<script>
import { startsWith } from '@helpers/string';
import CountrySelect from '@scenes/SelfserviceScene/components/CountrySelect';

export default {
  name: 'PhoneInput',

  components: {
    CountrySelect,
  },

  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
    baseNumber: {
      type: String,
      default: '',
    },
    countryCode: {
      type: Number,
      default: undefined,
    },
    numberDisabled: {
      type: Boolean,
      default: false,
    },
    hideCountryCode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      number: '',
      code: undefined,
      hasInitializedPhoneInfo: false,
      focus: false,
    };
  },

  computed: {
    countries() {
      return this.formsContainer.account.countries;
    },
    country() {
      // Maybe return country with hardcoded format?
      return this.getCountry(this.code);
    },
    codeString() {
      return this.country ? '+' + this.country.code : '';
    },
    input: {
      get() {
        return this.focus ? this.number : this.formatNumber();
      },
      /**
       * The input-field does not update, if there is no change to this.number. So this.number is
       * briefly set to value to update input-field.
       */
      set(value) {
        this.number = value;
        this.number = this.filterInput(value);
      },
    },
  },

  watch: {
    countries: {
      immediate: true,
      handler(to) {
        if (to?.length && !this.hasInitializedPhoneInfo) {
          this.initializePhoneInfo();
          this.hasInitializedPhoneInfo = true;
          this.$emit('change', this.getPhoneInfo());
        }
      },
    },
    country() {
      this.$emit('change', this.getPhoneInfo());
    },
    number() {
      this.$emit('change', this.getPhoneInfo());
    },
  },

  methods: {
    getPhoneInfo() {
      return {
        phoneNumber: this.codeString + this.number,
        countryCode: this.country?.code,
        baseNumber: this.number,
      };
    },
    initializePhoneInfo() {
      if (this.phoneNumber) {
        const info = this.extractInfoFromPhoneNumber(this.phoneNumber);
        this.code = info.countryCode;
        this.number = this.numberDisabled ? info.baseNumber : this.filterInput(info.baseNumber);
      } else {
        const country = this.getCountry(this.countryCode);
        this.code = country?.code ?? this.getCodeByLanguage();
        this.number = this.numberDisabled ? this.baseNumber : this.filterInput(this.baseNumber);
      }
    },
    extractInfoFromPhoneNumber(phoneNumber) {
      const code = this.extractCode(phoneNumber);
      const codeWasExtracted = code !== undefined;
      const number = codeWasExtracted ? phoneNumber.slice(code.toString().length + 1) : phoneNumber;
      const country = codeWasExtracted ? this.getCountry(code) : this.getCountryByLanguage();
      return { countryCode: country?.code, baseNumber: number };
    },
    extractCode(phoneNumber) {
      const country = this.countries.find(x => startsWith(phoneNumber, '+' + x.code));
      return country?.code;
    },
    getCountry(code) {
      return this.countries.find(x => x.code === code);
    },
    getCountryByLanguage() {
      return this.countries.find(x => x.code === this.getCodeByLanguage());
    },
    getCodeByLanguage() {
      switch (this.$config.LANGUAGE) {
        case 'da':
          return 45;
        case 'nb':
          return 47;
        default:
          return 45;
      }
    },
    formatNumber() {
      if (!this.number) return '';
      if (!this.country?.format) return this.number;
      return [...this.number]
        .reduce(
          (text, char) => (text.includes('x') ? text.replace('x', char) : text + char),
          this.country.format,
        )
        .split('x')[0];
    },
    filterInput(value) {
      return value.replace(/\D/g, '').substring(0, 10);
    },
    beforeInput(event) {
      if (event.data && /^\D$/.test(event.data)) event.preventDefault();
    },
  },

  inject: {
    formsContainer: {
      default() {
        console.error('Missing FormsContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.PhoneInput {
  display: flex;
  gap: 20px;

  .PhoneInput__select {
    flex: 1;
    min-width: 150px;
  }

  .PhoneInput__inputContainer {
    position: relative;
    display: flex;
    flex: 3;
    &:focus-within .PhoneInput__code {
      color: #999999;
    }
  }

  .PhoneInput__code {
    position: absolute;
    left: 0.7em;
    top: 50%;
    transform: translateY(-50%);
    color: #999999;
    user-select: none;
  }

  .PhoneInput__input {
    font-size: 14px;
    width: 100%;
    margin: 0;
    outline: 0;
    border: 1px solid #999999;
    border-radius: 0.2em;
    padding: 0.7em;
    box-shadow: none;
    background: none;
    background-color: #ffffff;
    user-select: none;
    transition: border-color 200ms ease-out, color 200ms ease-out, box-shadow 200ms ease-out;
    &:focus {
      border-color: #333333;
      box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.6), 0px 1px 10px rgba(0, 82, 156, 0.4);
    }
    &:disabled {
      background-color: $color-prim-grey-light;
    }
  }
}
</style>
