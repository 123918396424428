import FormsModule, { Actions } from '@store/modules/forms';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FormsContainer',

  computed: {
    ...mapState('forms', ['account']),
  },

  created() {
    if (!this.$store.hasModule('forms')) {
      this.$store.registerModule('forms', FormsModule);
      this.fetchAccountForms();
    }
  },

  methods: {
    ...mapActions('forms', {
      fetchAccountForms: Actions.FETCH_ACCOUNT_FORMS,
    }),
  },

  render() {
    return this.$scopedSlots.default({
      account: this.account,
    });
  },

  provide() {
    return {
      formsContainer: this,
    };
  },
};
