<template>
  <ModalDialog
    ref="modal"
    :title="$t('verification.verify_your_mobile_phone_number')"
    :working="working"
    :confirmLabel="confirmLabel"
    :disableConfirm="disableConfirm"
    :hideCancel="page === Pages.COMPLETE"
    class="PhoneVerificationDialog"
    @confirm="onConfirm"
    @close="validateStatus === Progress.COMPLETE && accountContainer.fetchAccount()"
  >
    <transition name="shift" mode="out-in" @after-enter="focusCodeInput">
      <div v-if="page === Pages.PHONE_NUMBER" key="pageOne" class="PhoneVerificationDialog__page">
        <div class="PhoneVerificationDialog__description">
          <span>{{ $t('verification.message.incoming_confirmation_code') }}</span>
        </div>
        <PhoneInput
          :baseNumber="number ? number : undefined"
          :countryCode="countryCode"
          numberDisabled
          hideCountryCode
          class="PhoneVerificationDialog__phoneInput"
          @change="onPhoneNumberChange"
        />
        <Alert
          v-if="verificationRequestStatus === Progress.ERROR"
          :level="AlertLevel.ERROR"
          hideIcon
          class="PhoneVerificationDialog__alert"
        >
          {{ $t('verification.message.invalid_number') }}
        </Alert>
      </div>
      <div v-else-if="page === Pages.CODE" key="pageTwo" class="PhoneVerificationDialog__page">
        <span>
          {{ $t('verification.message.sent_confirmation_code', { phone: phoneNumber }) }}
        </span>
        <CodeInput ref="codeInput" :codeLength="codeLength" @change="newCode => (code = newCode)" />
        <span class="PhoneVerificationDialog__resend">
          <span class="PhoneVerificationDialog__resendText">
            {{ $t('verification.message.missing_code') }}
          </span>
          <button class="PhoneVerificationDialog__resendButton" @click="resendCode">
            <i class="fas fa-redo-alt" />
            {{ $t('verification.controls.resend_code') }}
          </button>
        </span>
        <Alert
          v-if="validateStatus === Progress.ERROR"
          :level="AlertLevel.ERROR"
          hideIcon
          class="PhoneVerificationDialog__alert"
        >
          {{ $t('verification.message.wrong_code') }}
        </Alert>
      </div>

      <div
        v-else-if="page === Pages.COMPLETE"
        key="pageThree"
        class="PhoneVerificationDialog__page"
      >
        <i class="far fa-check-circle fa-7x PhoneVerificationDialog__confirmIcon" />
        <span class="PhoneVerificationDialog__completeText">
          {{ $t('verification.message.confirmed') }}
        </span>
      </div>
    </transition>
  </ModalDialog>
</template>

<script>
import { isDigitsOnly } from '@helpers/string';
import { ModalDialog } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';
import PhoneInput from './components/PhoneInput';
import CodeInput from './components/CodeInput';
import { postVerificationRequest, postValidate } from '@services/thansen/verification';
import Progress from '@types/Progress';

const Pages = {
  PHONE_NUMBER: 'PHONE_NUMBER',
  CODE: 'CODE',
  COMPLETE: 'COMPLETE',
};

export default {
  name: 'PhoneVerificationDialog',

  components: {
    ModalDialog,
    Alert,
    PhoneInput,
    CodeInput,
  },

  enums: {
    Pages,
    AlertLevel,
    Progress,
  },

  props: {
    number: {
      type: String,
      default: undefined,
    },
    countryCode: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      page: Pages.PHONE_NUMBER,

      phoneNumber: '',
      baseNumber: '',
      code: '',
      verificationId: '',
      codeLength: undefined,

      verificationRequestStatus: '',
      validateStatus: '',
    };
  },

  computed: {
    confirmLabel() {
      switch (this.page) {
        case Pages.PHONE_NUMBER:
          return this.$t('verification.controls.continue');
        case Pages.CODE:
          return this.$t('verification.controls.continue');
        case Pages.COMPLETE:
          return this.$t('verification.controls.finish');
        default:
          return '';
      }
    },
    disableConfirm() {
      switch (this.page) {
        case Pages.PHONE_NUMBER:
          return !this.isValidFormat;
        case Pages.CODE:
          return this.code.length !== this.codeLength;
        case Pages.COMPLETE:
          return false;
        default:
          return true;
      }
    },
    working() {
      switch (this.page) {
        case Pages.PHONE_NUMBER:
          return this.verificationRequestStatus === Progress.WORKING;
        case Pages.CODE:
          return (
            this.validateStatus === Progress.WORKING ||
            this.verificationRequestStatus === Progress.WORKING
          );
        case Pages.COMPLETE:
          return false;
        default:
          return false;
      }
    },
    isValidFormat() {
      const length = this.baseNumber.length;
      return length <= 10 && length > 0 && isDigitsOnly(this.baseNumber);
    },
  },

  methods: {
    onPhoneNumberChange(info) {
      this.phoneNumber = info.phoneNumber;
      this.baseNumber = info.baseNumber;
    },
    onConfirm() {
      switch (this.page) {
        case Pages.PHONE_NUMBER:
          this.confirmPhoneNumber();
          return;
        case Pages.CODE:
          this.confirmCode();
          return;
        case Pages.COMPLETE:
          this.close();
          return;
      }
    },
    confirmPhoneNumber() {
      this.verificationRequestStatus = Progress.WORKING;
      postVerificationRequest('sms', this.phoneNumber)
        .then(data => {
          this.verificationId = data.verification_id;
          this.codeLength = data.verification_code_length;
          this.verificationRequestStatus = Progress.COMPLETE;
          this.page = Pages.CODE;
        })
        .catch(() => {
          // Håndter fejl (hvilke type fejl skal håndteres?)
          this.verificationRequestStatus = Progress.ERROR;
        });
    },
    confirmCode() {
      this.validateStatus = Progress.WORKING;
      postValidate(this.verificationId, this.code)
        .then(data => {
          if (data.validation_result === 'wrong_code') {
            this.$refs.codeInput.clear();
            this.$refs.codeInput.focus();
            this.validateStatus = Progress.ERROR;
            return;
          }
          this.validateStatus = Progress.COMPLETE;
          this.page = Pages.COMPLETE;
        })
        .catch(() => {
          // Håndter fejl (hvilke type fejl skal håndteres?)
          this.validateStatus = Progress.ERROR;
        });
    },
    resendCode() {
      this.$refs.codeInput.clear();
      this.$refs.codeInput.focus();
      this.verificationRequestStatus = Progress.WORKING;
      postVerificationRequest('sms', this.phoneNumber)
        .then(data => {
          this.verificationId = data.verification_id;
          this.codeLength = data.verification_code_length;
          this.verificationRequestStatus = Progress.COMPLETE;
          this.page = Pages.CODE;
        })
        .catch(() => {
          // Håndter fejl (hvilke type fejl skal håndteres?)
          this.verificationRequestStatus = Progress.ERROR;
        });
    },
    open() {
      // transition happens twice if data is not reset before open
      this.page = Pages.PHONE_NUMBER;
      this.phoneNumber = '';
      this.baseNumber = '';
      this.code = '';
      this.verificationId = '';
      this.verificationRequestStatus = '';
      this.validateStatus = '';
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    focusCodeInput() {
      if (this.page === Pages.CODE) this.$refs.codeInput.focus();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.PhoneVerificationDialog {
  .shift-enter-active {
    transition: all 0.3s cubic-bezier(0.33, 1, 0.67, 1);
  }

  .shift-leave-active {
    transition: all 0.3s cubic-bezier(0.33, 0, 0.67, 0);
  }

  .shift-enter {
    opacity: 1;
    transform: translateX(150%);
  }

  .shift-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  .PhoneVerificationDialog__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
  }

  .PhoneVerificationDialog__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
  }

  .PhoneVerificationDialog__phoneNumber {
    font-size: 20px;
    font-weight: bold;
  }

  .PhoneVerificationDialog__phoneInput {
    width: 100%;
  }

  .PhoneVerificationDialog__resend {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PhoneVerificationDialog__resendText {
    color: #999999;
  }

  .PhoneVerificationDialog__resendButton {
    font-weight: bold;
    color: $color-prim-blue;
    border: none;
    padding: 5px 0;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: lighten($color-prim-blue, 20%);
    }
  }

  .PhoneVerificationDialog__alert {
    min-width: 50%;
  }

  .PhoneVerificationDialog__confirmIcon {
    align-self: center;
    color: $color-extra-success;
  }

  .PhoneVerificationDialog__completeText {
    margin-top: 10px;
  }
}
</style>
