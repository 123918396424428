<template>
  <div class="CodeInput">
    <input
      v-for="(input, index) in codeLength"
      :key="index"
      ref="input"
      type="text"
      maxlength="1"
      v-model="inputValues[index]"
      class="CodeInput__input"
      @beforeinput="event => onBeforeInput(event, index)"
      @input="event => onInput(event, index)"
      @keydown="event => onKeyDown(event, index)"
      @focus="onFocus"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { isDigitsOnly, startsWith } from '@helpers/string';

export default {
  name: 'CodeInput',

  props: {
    codeLength: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      inputValues: [],
    };
  },

  methods: {
    clear() {
      this.inputValues = [];
    },
    focus() {
      this.$refs.input[0].focus();
    },
    focusNext(index) {
      if (index + 1 < this.codeLength) this.$refs.input[index + 1].focus();
    },
    focusPrevious(index) {
      if (index > 0) this.$refs.input[index - 1].focus();
    },
    deletePrevious(index) {
      if (index > 0) Vue.set(this.inputValues, index - 1, '');
    },
    onBeforeInput(event, index) {
      const incomingText = event.data;
      const currentValue = event.target.value;
      const inputType = event.inputType;
      if (incomingText && !isDigitsOnly(incomingText)) event.preventDefault();
      if (!currentValue && startsWith(inputType, 'delete')) {
        event.preventDefault();
        this.focusPrevious(index);
        this.deletePrevious(index);
      }
    },
    onInput(event, index) {
      if (startsWith(event.inputType, 'insert')) this.focusNext(index);
      this.$emit('change', this.getCode());
    },
    onKeyDown(event, index) {
      if (event.keyCode === 39 || event.keyCode === 40) {
        event.preventDefault();
        this.focusNext(index);
      }
      if (event.keyCode === 37 || event.keyCode === 38) {
        event.preventDefault();
        this.focusPrevious(index);
      }
    },
    onFocus(event) {
      event.target.select();
    },
    getCode() {
      return this.inputValues.join('');
    },
  },
};
</script>

<style lang="scss" scoped>
.CodeInput {
  display: flex;
  gap: 20px;

  .CodeInput__input {
    text-align: center;
    width: 41px;
    margin: 0;
    outline: 0;
    border: 1px solid #999999;
    border-radius: 0.2em;
    padding: 0.7em;
    box-shadow: none;
    background: none;
    background-color: #ffffff;
    user-select: none;
    transition: border-color 200ms ease-out, color 200ms ease-out, box-shadow 200ms ease-out;
    &:focus {
      border-color: #333333;
      box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.6), 0px 1px 10px rgba(0, 82, 156, 0.4);
    }
  }
}
</style>
