import { Api } from '../';

export function postVerificationRequest(type, identifier) {
  return new Promise((resolve, reject) => {
    Api.post('verification/initiate', { type, identifier })
      .then(response => {
        if (response.data.errors || !response.data.data) reject(response.data.errors);
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postValidate(id, code) {
  return new Promise((resolve, reject) => {
    Api.post('verification/validate', { verification_id: id, verification_code: code })
      .then(response => {
        if (response.data.errors || !response.data.data) reject(response.data.errors);
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
