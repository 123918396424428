<template>
  <Select
    :label="label"
    :items="countries(formsContainer.account.countries, value)"
    :action="value => $emit('input', Number(value))"
    :disabled="disabled"
  />
</template>

<script>
import Select from '@components/Select';

export default {
  name: 'CountrySelect',

  components: {
    Select,
  },

  props: {
    value: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCodes: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    countries() {
      return this.formsContainer.account.countries.map(country => ({
        value: country.code,
        label: this.showCodes ? country.name + ' +' + country.code : country.name,
        isRefined: country.code === this.value,
      }));
    },
  },

  inject: {
    formsContainer: {
      default() {
        console.error('Missing FormsContainer');
        return;
      },
    },
  },
};
</script>
