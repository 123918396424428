import { getAccountForms } from '@services/thansen/forms';
import Progress from '@types/Progress';

export const Actions = {
  FETCH_ACCOUNT_FORMS: 'FETCH_ACCOUNT_FORMS',
};

const Mutations = {
  SET_ACCOUNT_FORMS: 'SET_ACCOUNT_FORMS',
  SET_ACCOUNT_FORMS_STATUS: 'SET_ACCOUNT_FORMS_STATUS',
};

export default {
  namespaced: true,

  state: {
    accountStatus: '',
    account: {
      countries: [],
    },
  },

  actions: {
    [Actions.FETCH_ACCOUNT_FORMS]({ commit, state }) {
      commit(Mutations.SET_ACCOUNT_FORMS_STATUS, Progress.WORKING);
      getAccountForms()
        .then(account => {
          commit(Mutations.SET_ACCOUNT_FORMS, account);
          commit(Mutations.SET_ACCOUNT_FORMS_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          commit(Mutations.SET_ACCOUNT_FORMS_STATUS, Progress.ERROR);
        });
    },
  },

  mutations: {
    [Mutations.SET_ACCOUNT_FORMS_STATUS](state, status) {
      state.accountStatus = status;
    },
    [Mutations.SET_ACCOUNT_FORMS](state, account) {
      state.account = account;
    },
  },
};
